<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<!-- <div class="animated fadeIn">      v-html="$_t(1, 'Add Location')"-->
			<div class="tabs-project" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
				<h1 class="pl-3" :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ FormMSG(12224324, 'Transports') }}
				</h1>
				<div class="card" v-if="$screen.width <= 576">
					<div class="pl-4 pr-4">
						<MyTransportFocusPage />
					</div>
				</div>
				<b-tabs v-else active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold">
					<b-tab :title="FormMSG(353535, 'My transport')">
						<MyTransportFocusPage />
					</b-tab>
					<b-tab lazy v-if="canSeeProdTransport" :title="FormMSG(3232323, 'Production transport')">
						<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
							<div class="form">
								<b-card class="mt-3" no-body>
									<b-card-text :class="{ 'bg-color-white': $screen.width < 992 }">
										<b-row class="hide-on-tablet">
											<b-col sm="6"></b-col>
											<b-col sm="6" class="text-right">
												<b-form-group>
													<b-button size="md" variant="outline-primary" @click="handleNewTransport">
														<plus-circle :size="16" /> &nbsp; {{ FormMSG(3, 'New Transport') }}
													</b-button>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col sm="12" md="5" lg="5" xl="5" :class="{ 'p-0': $screen.width < 576 }">
												<b-input-group v-if="transportsList.length > 0">
													<b-form-input
														type="text"
														v-model="filter"
														:placeholder="FormMSG(65, 'Type to search')"
													/><!-- @change="handleChangeFilter" -->
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon('Search')"
																color="#FFFFFF"
																:size="16"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getLucideIcon('X')"
																color="#FFFFFF"
																:size="16"
																:stroke-width="2.5"
																@click="filter = ''"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
											<b-col sm="12" md="7" lg="7" xl="7" :class="{ 'mt-3': $screen.width < 576 }">
												<div class="pb-3">
													<div class="float-right">
														<div class="d-flex w-100" :class="{ 'flex-wrap': $screen.width < 576 }">
															<div class="mr-3 text-color-australien pj-cb">
																<div class="fs-14">
																	<b v-if="allTransportation.length > 0"
																		><alert-triangle :size="16" /> {{ startPage }} - {{ totalPage }}
																		{{ this.FormMSG(25, 'of') }} {{ allTransportation.length }}
																		{{ this.FormMSG(26, 'records') }}</b
																	>
																	<b v-else>{{ this.FormMSG(33, 'No records') }}</b>
																</div>
															</div>
															<div class="mr-3 w-100-px">
																<v-select
																	v-model="setPage"
																	:options="pages"
																	@option:selected="setPagesFunc($event)"
																	label="text"
																	:reduce="(option) => option.value"
																	:clearable="false"
																/>
															</div>
															<div class="d-flex" :class="{ 'flex-basis-100 mt-2': $screen.width < 576 }">
																<b-button
																	variant="primary"
																	size="sm"
																	class="d-flex align-items-center mr-2 px-3"
																	:class="{ 'align-items-start': $screen.width < 576 }"
																	:disabled="startPage == 1"
																	@click="handleClickPrevious"
																>
																	<skip-back :size="16" />
																	<div class="ml-2" style="margin-top: 1px">{{ FormMSG(23, 'Prev') }}</div>
																</b-button>
																<b-button
																	class="d-flex align-items-center px-3"
																	:class="{ 'align-items-end': $screen.width < 576 }"
																	variant="primary"
																	:disabled="totalPage >= allTransportation.length"
																	size="sm"
																	@click="handleClickNext"
																>
																	<div class="mr-2" style="margin-top: 1px">{{ FormMSG(24, 'Next') }}</div>
																	<skip-forward :size="16" />
																</b-button>
															</div>
														</div>
													</div>
												</div>
											</b-col>
										</b-row>

										<b-row class="mt-3">
											<b-col :class="{ 'p-0': $screen.width < 576 }">
												<div v-for="(transport, i) in transportsList" class="hide-on-desktop" :key="i">
													<CollapsibleCard
														:cardData="transport"
														is-production
														@collapsible-card:copy-transport="handleCopyTransport"
														@collapsible-card:view-map="handleOpenNewWindow"
														@collapsible-card:from-there-to-transport="handleFromThereToTransport"
														@collapsible-card:delete-transport="handleDeleteTransport"
														@transport:edit="rowClicked"
													/>
												</div>
												<b-table
													v-if="transportsList"
													ref="transportsTable"
													class="hide-on-tablet"
													selectable
													bordered
													small
													responsive="sm"
													head-variant="dark"
													style="text-align: left"
													:items="transportsList"
													:fields="transportsFields"
													:hover="hover"
													:selected-variant="selectedColor"
													:select-mode="selectMode"
													:current-page="currentPage"
													:filter="filter"
													:per-page="perPage"
													:empty-text="FormMSG(587, 'No transport found')"
													show-empty
													@row-clicked="rowClicked"
												>
													<template #cell(departureArrivalTime)="{ item }">
														{{ rendTime(item.departureTime) + ' - ' + rendTime(item.arrivalTime) }}
													</template>
													<template #cell(km)="{ item }">
														{{ item.km + ' ' + distanceUnit }}
													</template>
													<template #cell(kgCoTwo)="{ item }">
														{{ rendCo2(item.kgCoTwo) }}
													</template>
													<template #cell(type)="{ item }">
														{{ rendTransportationMean(item.type) }}
													</template>
													<template #cell(routeAddress)="{ item }">
														<span v-if="item.fromAddress.street === '' && item.toAddress.street === ''"> ... </span>
														<span v-else>
															<span v-html="rendFormattedAddress(item.fromAddress)" />
															<i class="icon-arrow-right" style="color: #00a09c" />
															<span v-html="rendFormattedAddress(item.toAddress)" />
														</span>
													</template>
													<!-- <template #cell(effActions)="row">
                      <i v-if="row.value.__transportStatus === 'started'" class="icon-plane" />
                      <i v-if="row.value.__transportStatus === 'arrived'" class="icon-flag" />
                    </template>
                    <template #cell(showPassengers)="{ item }">
                      <b-button size="sm" @click="handleShowPassengers(item.id)">
                        <i class="icon-user" />
                      </b-button>
                    </template>
                    <template #cell(mapUrl)="{ item }">
                      <b-button size="sm" :disabled="item.fromAddressId === 0 || item.toAddressId === 0" @click="handleOpenNewWindow(item)">
                        <i class="icon-map" />
                      </b-button>
                    </template> -->
													<template #cell(options)="{ item }">
														<b-dropdown
															no-caret
															dropleft
															class="custom-dropdown"
															no-flip
															block
															offset="25"
															variant="none"
															style="border-right: 0px !important; padding-top: 0px !important; position: relative"
														>
															<template #button-content>
																<more-vertical />
															</template>
															<b-dropdown-item-button @click="handleCopyTransport(item)"
																><copy /> &nbsp; <b>{{ FormMSG(867786, 'Copy') }}</b>
															</b-dropdown-item-button>
															<b-dropdown-item-button
																:disabled="item.fromAddressId === 0 || item.toAddressId === 0"
																@click="handleOpenNewWindow(item)"
																><map-pin /> &nbsp; <b>{{ FormMSG(61, 'View in map') }}</b></b-dropdown-item-button
															>
															<b-dropdown-item-button
																v-if="item.arrivalTime !== '0001-01-01T00:00:00Z'"
																@click="handleFromThereToTransport(item.id)"
															>
																<car /> &nbsp; <b>{{ FormMSG(689689, 'From here go to') }}</b>
															</b-dropdown-item-button>
															<b-dropdown-item-button @click="handleDeleteTransport(item)"
																><trash color="tomato" /> &nbsp;
																<b style="color: tomato">{{ FormMSG(63, 'Remove') }}</b></b-dropdown-item-button
															>
														</b-dropdown>
													</template>
												</b-table>
											</b-col>
										</b-row>
									</b-card-text>
								</b-card>
							</div>

							<b-row class="footer-fixed hide-on-desktop">
								<b-col md="12">
									<b-button class="w-100 mb-2" size="lg">
										{{ FormMSG(700, 'Add new transport') }}
									</b-button>
								</b-col>
							</b-row>
						</div>
					</b-tab>
				</b-tabs>
			</div>
		</transition>

		<transport-modal
			:open="showModalTransport"
			:edit-data="currTransportToEdit"
			@transport-modal:close="handleTransportModalClose"
			@transport-modal:submit-success="handleTransportModalSubmitSuccess"
		/>
	</errorContainer>
</template>

<script>
import moment from 'moment';
import { isNil, omit } from '~utils';
import { getTransports, delTransport, createTransport, getTransport, updatedTransport, addUpdPassenger } from '@/cruds/transports.crud';
import { isArrived } from '@/modules/transports/utils';
import { MoreVertical, Car, Trash2 as Trash, Search, X, Copy, MapPin, SkipBack, SkipForward, PlusCircle, AlertTriangle } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import transportMixin from '@/modules/transports/transport.mixin';
import { rendKgCo2 } from '../../../shared/helpers';
import { store } from '@/store/index';
import TransportModal from '@/modules/transports/components/TransportModal';
import _ from 'lodash';
import globalMixin from '@/mixins/global.mixin';
import CollapsibleCard from '@/modules/transports/views/my/collapsibleCard';
import { makeDirLink } from '@/shared/google/helpers';
import { getAddress } from '@/cruds/address.crud';
import { mapActions } from 'vuex';
import MyTransportFocusPage from './my/index.vue';

export default {
	name: 'TransportsListComponentPage',
	components: {
		Search,
		X,
		MyTransportFocusPage,
		Trash,
		MoreVertical,
		Copy,
		MapPin,
		Car,
		SkipBack,
		SkipForward,
		PlusCircle,
		AlertTriangle,
		TransportModal,
		CollapsibleCard
	},
	mixins: [languageMessages, transportMixin, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			erreur: {},
			pages: [],
			setPage: 0,
			startPage: 1,
			totalPage: 0,
			warning: '',
			filter: '',
			currentPage: 1,
			perPage: 100,
			selectMode: 'single',
			selectedColor: 'primary',
			transportsList: [],
			isTransportLoading: false,
			allTransportation: [],

			currTransportIdToEdit: 0,
			currTransportToEdit: {},
			showModalTransport: false
		};
	},
	computed: {
		canSeeProdTransport() {
			return store.canManageTransport();
		},

		/**
		 * @return {Array}
		 */
		transportsFields() {
			return [
				// {
				// 	key: 'effActions',
				// 	label: this.FormMSG(5123424, 'Action'),
				// 	sortable: true,
				// 	formatter: (v, t, root) => ({
				// 		...root,
				// 		__transportStatus: this.checkTransportStatus(root)
				// 	})
				// },
				{
					key: 'date',
					label: this.FormMSG(5467, 'Date'),
					sortable: true,
					formatter: (v, t, root) => moment(new Date(root.departureDate)).format('DD/MM/YYYY')
				},
				{
					key: 'departureArrivalTime',
					label: this.FormMSG(50, 'Departure/Arrival Time'),
					sortable: true
				},
				{
					key: 'extraInfo',
					label: this.FormMSG(51, 'Description')
				},
				{
					key: 'type',
					label: this.FormMSG(53, 'Transportation mean'),
					sortable: true
				},
				{
					key: 'user',
					label: this.FormMSG(55, 'Crew member'),
					sortable: true,
					formatter: (v) => (v.firstName === '' && v.name === '' ? '...' : `${this.capitalize(v.firstName)} ${this.capitalize(v.name)}`)
				},
				{
					key: 'routeAddress',
					label: this.FormMSG(894498, 'Route'),
					sortable: false,
					formatter: (v) => v
				},
				{
					key: 'vehicule',
					label: this.FormMSG(59614, 'Vehicule'),
					sortable: true,
					formatter: (v) => (v.name === '' && v.numberPlate === '' ? '...' : `${this.capitalize(v.name)} (${v.numberPlate})`)
				},
				// {
				// 	key: 'showPassengers',
				// 	label: this.FormMSG(8979898, 'Passengers'),
				// 	sortable: false,
				// 	formatter: (v) => v
				// },
				{
					key: 'km',
					label: this.FormMSG(60, 'Distance'),
					sortable: true
				},
				{
					key: 'kgCoTwo',
					label: this.FormMSG(62, 'CO2'),
					sortable: true
				},

				// {
				// 	key: 'mapUrl',
				// 	label: this.FormMSG(73587667, 'Map'),
				// 	sortable: false,
				// 	formatter: (v) => v
				// },
				{
					key: 'options',
					label: this.FormMSG(73567, 'Options'),
					sortable: false,
					formatter: (v) => v
				}
			];
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	async created() {
		await this.initTransports();
	},
	methods: {
		...mapActions({
			setCurrentTransportId: 'transport/setCurrentTransportId',
			setCurrentTransport: 'transport/setCurrentTransport',
			setMyLocationToday: 'global-filter/setMyLocationToday'
		}),

		async handleOpenNewWindow(item) {
			const origin = await getAddress(item.fromAddressId);
			const destination = await getAddress(item.toAddressId);

			window.open(makeDirLink(origin, destination), this.FormMSG(213421, 'Google map link'), 'height=670,width=970,modal=yes,alwaysRaised=yes');
		},
		async handleTransportModalSubmitSuccess() {
			this.currTransportIdToEdit = 0;
			this.currTransportToEdit = {};
			await this.initTransports();
		},
		handleTransportModalClose() {
			this.currTransportIdToEdit = 0;
			this.currTransportToEdit = {};
			this.showModalTransport = false;
		},
		handleClickNext() {
			this.startPage = this.startPage + this.setPage;
			let total = this.totalPage + this.setPage;
			if (total >= this.allTransportation.length) {
				this.totalPage = this.allTransportation.length;
			} else {
				this.totalPage = total;
			}
			this.transportsList = this.allTransportation.slice(this.startPage - 1, this.totalPage - 1);
		},
		handleClickPrevious() {
			let decreaseTotal = this.startPage - this.setPage;
			let total = this.totalPage - this.setPage;
			if (decreaseTotal <= 0) {
				this.startPage = 1;
			} else {
				this.startPage = decreaseTotal;
			}
			if (total < this.setPage) {
				this.totalPage = this.setPage;
			} else {
				this.totalPage = total;
			}
			this.transportsList = this.allTransportation.slice(this.startPage - 1, this.totalPage - 1);
		},
		setPagesFunc(e) {
			let oldSetPage = this.setPage;
			let newSetPage = e;
			let newTotalPage = this.startPage + newSetPage - 1;

			if (newTotalPage > this.allTransportation.length) {
				newTotalPage = this.allTransportation.length;
			}

			this.setPage = newSetPage;
			this.totalPage = newTotalPage;

			if (oldSetPage < newSetPage) {
				// If we're increasing the page size, we don't need to change startPage or transportsList
				this.transportsList = this.allTransportation.slice(this.startPage - 1, this.totalPage - 1);
			} else if (oldSetPage > newSetPage) {
				// If we're decreasing the page size, we need to adjust the startPage to display the same data
				this.startPage = this.startPage + oldSetPage - newSetPage;
				this.transportsList = this.allTransportation.slice(this.startPage - 1, this.totalPage - 1);
			} else {
				// If we're not changing the page size, we just need to update transportsList
				this.transportsList = this.allTransportation.slice(this.startPage - 1, this.totalPage - 1);
			}
		},
		setFilterSize(count) {
			const pageSizes = [100, 200, 300, 500, 1000, 2000, 5000, 10000];
			this.startPage = 1;
			let arrayPage = [];
			this.setPage = 100;
			this.totalPage = 100;
			this.transportsList = this.allTransportation.slice(0, 100);
			if (count <= 100) {
				arrayPage.push(count);
				this.setPage = count;
				this.totalPage = count;
				this.pages = arrayPage;
			} else {
				for (let i = 0; i < pageSizes.length; i++) {
					const pageSize = pageSizes[i];
					if (pageSize >= count) {
						arrayPage.push(count);
						break;
					} else {
						arrayPage.push(pageSize);
					}
				}
				this.pages = arrayPage;
			}
		},
		rendCo2(co2) {
			return rendKgCo2(co2);
		},
		rendTime(time) {
			if (time === '0001-01-01T00:00:00Z') return '--:--';
			return moment(time).format('HH:mm');
		},
		async initTransports() {
			this.isTransportLoading = true;
			this.allTransportation = await getTransports();
			this.setFilterSize(this.allTransportation.length);
			this.isTransportLoading = false;
		},

		async handleNewTransport() {
			const result = await createTransport();

			if (this.$screen.width > 567) {
				this.currTransportToEdit = {
					...result
				};
				this.showModalTransport = true;
			} else {
				this.setCurrentTransportId(parseInt(result.id));
				this.setCurrentTransport({
					...result
				});

				this.$router.push({
					path: '/TransportsList/mobile/add'
				});
			}
			// this.$router.push({ name: 'Transport', params: { id } });
		},

		handleClearFilter() {
			this.filter = '';
		},

		async handleCopyTransport(item) {
			const resCreateTransport = await createTransport();
			let origin = await getTransport(item.id);

			if (item.passenger.length > 0) {
				for (let i = 0; i < item.passenger.length; i++) {
					const option = item.passenger[i];

					await addUpdPassenger({
						transportId: parseInt(resCreateTransport.id),
						passengerId: 0,
						updatedPassenger: {
							firstName: option.firstName,
							name: option.name,
							phone: option.phone
						}
					});
				}
			}

			const result = await updatedTransport(
				parseInt(resCreateTransport.id),
				omit(
					[
						'id',
						'ett',
						'effDepartureTime',
						'effArrivalTime',
						'eta',
						'curLat',
						'curLng',
						'createdByUser',
						'user',
						'fromUser',
						'toUser',
						'fromLocation',
						'toLocation',
						'fromAddress',
						'toAddress',
						'vehicule',
						'__typename'
					],
					origin
				)
			);
			this.currTransportIdToEdit = parseInt(resCreateTransport.id, 10);
			this.currTransportToEdit = { ...result.AddUpdTransport };
			this.showModalTransport = true;
			// this.$router.push({ name: 'Transport', params: { id } });
		},

		async handleFromThereToTransport(originId) {
			// const { id } = await createTransport();
			const origin = await getTransport(originId);
			let payload = {
				...omit(
					[
						'id',
						'ett',
						'effDepartureTime',
						'effArrivalTime',
						'eta',
						'curLat',
						'curLng',

						'toAddress',
						'fromAddress',

						'toAddressId',
						'toLocationId',
						'toUserId',

						'fromAddressId',
						'fromLocationId',
						'fromUserId'
					],
					origin
				),
				fromAddress: origin.toAddress,
				fromAddressId: origin.toAddressId,
				fromLocationId: origin.toLocationId,
				fromUserId: origin.toUserId,
				departureTime: origin.arrivalTime
			};

			payload = _.omit(payload, ['arrivalTime']);

			const result = await updatedTransport(0, payload);
			this.currTransportIdToEdit = parseInt(result.id, 10);
			this.currTransportToEdit = { ...result.AddUpdTransport };
			this.showModalTransport = true;
			// this.$router.push({ name: 'Transport', params: { id } });
		},

		/**
		 * @param {String} transportId
		 */
		handleDeleteTransport(transport) {
			if (isNil(transport)) return;
			const { id } = transport;

			const h = this.$createElement;

			const messageVNode = h('div', {}, [
				h(
					'div',
					{
						class: ['alert alert-danger'],
						style: !isArrived(transport) ? 'display:none;' : ''
					},
					[
						h('span', {}, [
							this.FormMSG(2300923, 'This transport has been carried out. If you delete it,') + ' ',
							h('b', this.FormMSG(908809, 'all associated data will be permanently deleted you cannot go back.') + ' '),
							this.FormMSG(7893278, 'Are you sure you want to delete this transport?')
						]),
						h('br')
					]
				),
				h('div', {}, [h('span', {}, [this.FormMSG(876867, 'Please confirm that you want delete this transport')])])
			]);

			this.$bvModal
				.msgBoxConfirm([messageVNode], {
					title: this.FormMSG(55553, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(55551, 'YES'),
					cancelTitle: this.FormMSG(55552, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((s) => {
					if (!s) return;
					const list = this.transportsList;
					const index = list.findIndex((l) => l.id === id);
					if (~index) list.splice(index, 1);
					delTransport(id);
				})
				.catch((err) => console.log(err));
		},

		/**
		 * @param {Object} transport
		 */
		rowClicked(transport) {
			if (this.$screen.width > 567) {
				this.currTransportIdToEdit = parseInt(transport.id, 10);
				this.currTransportToEdit = transport;
				this.showModalTransport = true;
			} else {
				this.setCurrentTransportId(parseInt(transport.id, 10));
				this.setCurrentTransport(transport);

				this.$router.push({
					path: '/TransportsList/mobile/update/' + transport.id
				});
			}
			// this.$router.push({ name: 'Transport', params: { id: transport.id } });
		}
	}
};
</script>
