var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "tabs-project",
            class: `${_vm.$screen.width <= 576 ? "my-project" : ""}`,
          },
          [
            _c(
              "h1",
              {
                staticClass: "pl-3",
                class: [
                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                  { "is-pwa": _vm.$isPwa() },
                ],
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.FormMSG(12224324, "Transports")) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
            _vm.$screen.width <= 576
              ? _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    { staticClass: "pl-4 pr-4" },
                    [_c("MyTransportFocusPage")],
                    1
                  ),
                ])
              : _c(
                  "b-tabs",
                  {
                    attrs: {
                      "active-nav-item-class": "font-weight-bold",
                      "active-tab-class": "font-weight-bold",
                    },
                  },
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: _vm.FormMSG(353535, "My transport") } },
                      [_c("MyTransportFocusPage")],
                      1
                    ),
                    _vm.canSeeProdTransport
                      ? _c(
                          "b-tab",
                          {
                            attrs: {
                              lazy: "",
                              title: _vm.FormMSG(
                                3232323,
                                "Production transport"
                              ),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                class:
                                  _vm.$screen.width > 576
                                    ? "container-layout"
                                    : "container-mobile",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form" },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-3",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          {
                                            class: {
                                              "bg-color-white":
                                                _vm.$screen.width < 992,
                                            },
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "hide-on-tablet" },
                                              [
                                                _c("b-col", {
                                                  attrs: { sm: "6" },
                                                }),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "text-right",
                                                    attrs: { sm: "6" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              size: "md",
                                                              variant:
                                                                "outline-primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.handleNewTransport,
                                                            },
                                                          },
                                                          [
                                                            _c("plus-circle", {
                                                              attrs: {
                                                                size: 16,
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "   " +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    3,
                                                                    "New Transport"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    class: {
                                                      "p-0":
                                                        _vm.$screen.width < 576,
                                                    },
                                                    attrs: {
                                                      sm: "12",
                                                      md: "5",
                                                      lg: "5",
                                                      xl: "5",
                                                    },
                                                  },
                                                  [
                                                    _vm.transportsList.length >
                                                    0
                                                      ? _c(
                                                          "b-input-group",
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    65,
                                                                    "Type to search"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.filter,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.filter =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "filter",
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              {
                                                                staticClass:
                                                                  "cursor-pointer",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticClass:
                                                                      "btn-search",
                                                                  },
                                                                  [
                                                                    _vm.filter
                                                                      .length ===
                                                                    0
                                                                      ? _c(
                                                                          _vm.getLucideIcon(
                                                                            "Search"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#FFFFFF",
                                                                                size: 16,
                                                                                "stroke-width": 2.5,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _c(
                                                                          _vm.getLucideIcon(
                                                                            "X"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#FFFFFF",
                                                                                size: 16,
                                                                                "stroke-width": 2.5,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.filter =
                                                                                    ""
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    class: {
                                                      "mt-3":
                                                        _vm.$screen.width < 576,
                                                    },
                                                    attrs: {
                                                      sm: "12",
                                                      md: "7",
                                                      lg: "7",
                                                      xl: "7",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "pb-3" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "float-right",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex w-100",
                                                                class: {
                                                                  "flex-wrap":
                                                                    _vm.$screen
                                                                      .width <
                                                                    576,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mr-3 text-color-australien pj-cb",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "fs-14",
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .allTransportation
                                                                          .length >
                                                                        0
                                                                          ? _c(
                                                                              "b",
                                                                              [
                                                                                _c(
                                                                                  "alert-triangle",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.startPage
                                                                                    ) +
                                                                                    " - " +
                                                                                    _vm._s(
                                                                                      _vm.totalPage
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      this.FormMSG(
                                                                                        25,
                                                                                        "of"
                                                                                      )
                                                                                    ) +
                                                                                    " " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .allTransportation
                                                                                        .length
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      this.FormMSG(
                                                                                        26,
                                                                                        "records"
                                                                                      )
                                                                                    )
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    this.FormMSG(
                                                                                      33,
                                                                                      "No records"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mr-3 w-100-px",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          options:
                                                                            _vm.pages,
                                                                          label:
                                                                            "text",
                                                                          reduce:
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option.value,
                                                                          clearable: false,
                                                                        },
                                                                        on: {
                                                                          "option:selected":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.setPagesFunc(
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.setPage,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.setPage =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "setPage",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex",
                                                                    class: {
                                                                      "flex-basis-100 mt-2":
                                                                        _vm
                                                                          .$screen
                                                                          .width <
                                                                        576,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-items-center mr-2 px-3",
                                                                        class: {
                                                                          "align-items-start":
                                                                            _vm
                                                                              .$screen
                                                                              .width <
                                                                            576,
                                                                        },
                                                                        attrs: {
                                                                          variant:
                                                                            "primary",
                                                                          size: "sm",
                                                                          disabled:
                                                                            _vm.startPage ==
                                                                            1,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.handleClickPrevious,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "skip-back",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-top":
                                                                                  "1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  23,
                                                                                  "Prev"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-items-center px-3",
                                                                        class: {
                                                                          "align-items-end":
                                                                            _vm
                                                                              .$screen
                                                                              .width <
                                                                            576,
                                                                        },
                                                                        attrs: {
                                                                          variant:
                                                                            "primary",
                                                                          disabled:
                                                                            _vm.totalPage >=
                                                                            _vm
                                                                              .allTransportation
                                                                              .length,
                                                                          size: "sm",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.handleClickNext,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-top":
                                                                                  "1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  24,
                                                                                  "Next"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "skip-forward",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              { staticClass: "mt-3" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    class: {
                                                      "p-0":
                                                        _vm.$screen.width < 576,
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.transportsList,
                                                      function (transport, i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "hide-on-desktop",
                                                          },
                                                          [
                                                            _c(
                                                              "CollapsibleCard",
                                                              {
                                                                attrs: {
                                                                  cardData:
                                                                    transport,
                                                                  "is-production":
                                                                    "",
                                                                },
                                                                on: {
                                                                  "collapsible-card:copy-transport":
                                                                    _vm.handleCopyTransport,
                                                                  "collapsible-card:view-map":
                                                                    _vm.handleOpenNewWindow,
                                                                  "collapsible-card:from-there-to-transport":
                                                                    _vm.handleFromThereToTransport,
                                                                  "collapsible-card:delete-transport":
                                                                    _vm.handleDeleteTransport,
                                                                  "transport:edit":
                                                                    _vm.rowClicked,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    _vm.transportsList
                                                      ? _c("b-table", {
                                                          ref: "transportsTable",
                                                          staticClass:
                                                            "hide-on-tablet",
                                                          staticStyle: {
                                                            "text-align":
                                                              "left",
                                                          },
                                                          attrs: {
                                                            selectable: "",
                                                            bordered: "",
                                                            small: "",
                                                            responsive: "sm",
                                                            "head-variant":
                                                              "dark",
                                                            items:
                                                              _vm.transportsList,
                                                            fields:
                                                              _vm.transportsFields,
                                                            hover: _vm.hover,
                                                            "selected-variant":
                                                              _vm.selectedColor,
                                                            "select-mode":
                                                              _vm.selectMode,
                                                            "current-page":
                                                              _vm.currentPage,
                                                            filter: _vm.filter,
                                                            "per-page":
                                                              _vm.perPage,
                                                            "empty-text":
                                                              _vm.FormMSG(
                                                                587,
                                                                "No transport found"
                                                              ),
                                                            "show-empty": "",
                                                          },
                                                          on: {
                                                            "row-clicked":
                                                              _vm.rowClicked,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "cell(departureArrivalTime)",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.rendTime(
                                                                            item.departureTime
                                                                          ) +
                                                                            " - " +
                                                                            _vm.rendTime(
                                                                              item.arrivalTime
                                                                            )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(km)",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          item.km +
                                                                            " " +
                                                                            _vm.distanceUnit
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(kgCoTwo)",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.rendCo2(
                                                                            item.kgCoTwo
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(type)",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.rendTransportationMean(
                                                                            item.type
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(routeAddress)",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    item
                                                                      .fromAddress
                                                                      .street ===
                                                                      "" &&
                                                                    item
                                                                      .toAddress
                                                                      .street ===
                                                                      ""
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " ... "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        _vm.rendFormattedAddress(
                                                                                          item.fromAddress
                                                                                        )
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "icon-arrow-right",
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#00a09c",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        _vm.rendFormattedAddress(
                                                                                          item.toAddress
                                                                                        )
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(options)",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "b-dropdown",
                                                                      {
                                                                        staticClass:
                                                                          "custom-dropdown",
                                                                        staticStyle:
                                                                          {
                                                                            "border-right":
                                                                              "0px !important",
                                                                            "padding-top":
                                                                              "0px !important",
                                                                            position:
                                                                              "relative",
                                                                          },
                                                                        attrs: {
                                                                          "no-caret":
                                                                            "",
                                                                          dropleft:
                                                                            "",
                                                                          "no-flip":
                                                                            "",
                                                                          block:
                                                                            "",
                                                                          offset:
                                                                            "25",
                                                                          variant:
                                                                            "none",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "button-content",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      "more-vertical"
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-dropdown-item-button",
                                                                          {
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleCopyTransport(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "copy"
                                                                            ),
                                                                            _vm._v(
                                                                              "   "
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      867786,
                                                                                      "Copy"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-dropdown-item-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  item.fromAddressId ===
                                                                                    0 ||
                                                                                  item.toAddressId ===
                                                                                    0,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleOpenNewWindow(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "map-pin"
                                                                            ),
                                                                            _vm._v(
                                                                              "   "
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      61,
                                                                                      "View in map"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        item.arrivalTime !==
                                                                        "0001-01-01T00:00:00Z"
                                                                          ? _c(
                                                                              "b-dropdown-item-button",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.handleFromThereToTransport(
                                                                                        item.id
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "car"
                                                                                ),
                                                                                _vm._v(
                                                                                  "   "
                                                                                ),
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          689689,
                                                                                          "From here go to"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "b-dropdown-item-button",
                                                                          {
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleDeleteTransport(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "trash",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "tomato",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "tomato",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      63,
                                                                                      "Remove"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2539098248
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "footer-fixed hide-on-desktop",
                                  },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "12" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "w-100 mb-2",
                                            attrs: { size: "lg" },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    700,
                                                    "Add new transport"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
      _c("transport-modal", {
        attrs: {
          open: _vm.showModalTransport,
          "edit-data": _vm.currTransportToEdit,
        },
        on: {
          "transport-modal:close": _vm.handleTransportModalClose,
          "transport-modal:submit-success":
            _vm.handleTransportModalSubmitSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }